.outerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
}

.innerWrapper {
  position: absolute;
  font-size: 13px;
  z-index: 5000000;
}

.navbarContainer {
  width: max-content;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0 calc(var(--nav-item-padding) * 1.5);
  padding-top: 0.5rem;
  animation: slideDown 0.4s ease-out;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.list a {
  color: #dbdbdb;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--nav-item-padding);
}

.list .text {
  font-size: .8em;
  opacity: 0;
  pointer-events: none;
  transition: 250ms ease-in-out;
  position: absolute;
  bottom: calc(.5 * var(--nav-item-padding));
  transform: translateY(-50%);
}

.list .icon {
  position: relative;
  transition: 250ms ease-in-out;
}

.list .icon svg {
  color: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  display: block;
}

.list .active .text {
  pointer-events: all;
  opacity: 1;
  transform: translateY(calc(-80% - var(--nav-item-padding)));
}

.list .active .icon {
  transform: translateY(calc(50% + var(--nav-item-padding)));
}

.list .active .icon svg {
  color: hsl(0, 0%, 13%);
}

.list {
  position: relative;
}

.indicator {
  position: absolute;
  left: calc(var(--position) * (var(--icon-size) + var(--nav-item-padding) * 2));
  transition: 250ms ease-in-out;
}

.indicator::after,
.indicator::before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.indicator::after {
  background-color: hsl(52, 100%, 57%);
  width: calc(var(--icon-size) * 2);
  height: calc(var(--icon-size) * 2);
  top: calc(1 * var(--icon-size));
}
.indicator::before {
  background-color: var(--background-color);
  width: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  height: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  top: calc(1 * var(--icon-size) - var(--indicator-spacing));
  left: calc(-1 * var(--indicator-spacing));
}

.corners::before {
  content: "";
  box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  /* background-color: white; */
  z-index: 1;
  top: calc(14 * var(--indicator-spacing));
  left: calc(.1 * var(--indicator-spacing));
  transform: translateX(-100%);
  border-bottom-right-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color: var(--background-color);
  border-style: solid;
  border-top: none;
  border-left: none;
}

.corners::after {
  content: "";
  box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  /* background-color: white; */
  z-index: 1;
  top: calc(14 * var(--indicator-spacing));
  left: calc(var(--icon-size) * 2 + -.2 * var(--indicator-spacing));
  border-bottom-left-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color: var(--background-color);
  border-style: solid;
  border-top: none;
  border-right: none;
}


@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}