/* Define keyframe animations */
@keyframes headerAnimation {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes staggeredTextAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}




@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }

  100% {
    opacity: 1;
  }
}

/* Apply animations to specific elements using classNames */
.headerAnimation {
  animation: headerAnimation 0.8s ease 0s 1 normal forwards;
}

/* .staggeredText span {
  opacity: 0;
  animation: staggeredTextAnimation 0.5s ease 0.8s 1 normal forwards;
} */


.staggeredText span {

  animation-name: animateIn;
  animation-duration: 350ms;
  animation-delay: calc(var(--animation-order) * 400ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.staggeredText span  + .staggeredText span {
  margin-top: 16px;
}